import React from 'react';
import { Link } from 'react-router-dom';
export default function TreadmillTest() {
  return (
    <>
      <div className='services-item container'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/clinic-visit-03.png`} alt="Home Visits" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                <div className='services-item-content'>
                <h2>Treadmill <span>Test</span></h2>          
                <p>At FamPhy, we offer Treadmill Test (TMT) services, also known as Exercise Stress Tests, to evaluate how your heart functions during physical activity. This test is crucial for identifying heart issues that may not be apparent at rest, helping to detect conditions such as coronary artery disease and exercise-induced arrhythmias. Our highly trained professionals and advanced equipment ensure you receive accurate results in a comfortable and safe environment.</p>
                <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                </div>
            </div>          
        </div>
      </div>
    </>
  );
}
