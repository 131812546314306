import React from 'react';
import PayUMoneyIntegration from './PayUMoneyIntegration';

export default function onlinePayment() {
  return (
    <>
      <div className='Inner-banner'>
          <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-inner-banner-payment.jpg`} alt="Famphy Banner" />
      </div>
      <div className='onlie-payment'>
        <div className="container mt-5 mb-5"> 
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 bookapointment-form">
              <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/upi-scaner.jpg`} alt="Famphy Pay online Using UPI" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 bookapointment-form">
             {/*<PayUMoneyIntegration />      */}      
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
