// App.js
import './assets/css/teme-style.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import OurServices from './components/OurServices';
import ClinicVisitBhartiyaCity from './components/ClinicVisit-BhartiyaCity';
import ClinicVisitNarayanapura from './components/ClinicVisit-Narayanapura';
import AboutUs from './components/AboutUs';
import FAQ from './components/FAQ';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContactUs from './components/ContactUs';
import AppointmentForm from './components/BookAppointment';
import OnlinePayment from './components/onlinePayment';
import FamphyGallery from './components/famphyGallery';
import Contact from './components/Contact';
function App() {

    return (
        <>
            <Router>
                <Header />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/our-services" element={<OurServices />} />
                    <Route exact path='/ClinicVisit-BhartiyaCity' element={<ClinicVisitBhartiyaCity/>} />
                    <Route exact path='/ClinicVisit-Narayanapura' element={<ClinicVisitNarayanapura/>} />
                    <Route exact path="/about-us" element={<AboutUs />} />
                    <Route exact path="/faq" element={<FAQ />} />
                    <Route exact path="/contacts" element={<ContactUs />} />
                    <Route exact path="/book-appointment" element={<AppointmentForm />} />
                    <Route exact path='/online-payment' element={<OnlinePayment/>} />
                    <Route exact path='/famphy-gallery' element={<FamphyGallery/>} />
                    <Route exact path='/contact' element={<Contact/>} />
                </Routes> 
                <Footer />               
            </Router>  
        </>
    );
}
export default App;
