import React from 'react';
import { Link } from 'react-router-dom';
export default function ClinicECG() {
  return (
    <>
        <div className='services-item container'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle mob-order-2 p-0'>
                    <div className='services-item-content'>
                    <h2><span>ECG</span></h2>          
                    <p>ECG services are available in the clinic and at home, with immediate interpretation by a physician.</p>
                    <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 mob-order-1 p-0'>
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/clinic-visit-02.png`} alt="Home Visits" />
                </div>
            </div>
        </div>
    </>
  );
}
