import React from 'react';
import { Link } from 'react-router-dom';
export default function ClinicDayCare() {
  return (
    <>
      <div className='services-item container'>
            <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/clinic-visit-daycare.jpg`} alt="Home Visits" />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                    <div className='services-item-content'>
                    <h2><span>Day Care Procedures</span></h2> 
                    <h4>General Procedures:</h4>         
                    <ul className='list-unstyled text-start margin-left-30'>
                    <li><i class="fas fa-arrow-circle-right"></i> Corn Removal</li>
                    <li><i class="fas fa-arrow-circle-right"></i> Foreign Body Removal</li>
                    <li><i class="fas fa-arrow-circle-right"></i> Lipoma</li>
                    <li><i class="fas fa-arrow-circle-right"></i> Sebaceous Cyst</li>
                    <li><i class="fas fa-arrow-circle-right"></i> Toe Nail Removal</li>
                    </ul>
                    <h4>Dermatology Procedures:</h4>       
                    <ul className='list-unstyled text-start margin-left-30'>
                      <li><i class="fas fa-arrow-circle-right"></i> Mole Removal</li>
                      <li><i class="fas fa-arrow-circle-right"></i> Wart Removal</li>
                      <li><i class="fas fa-arrow-circle-right"></i> Skin Tags</li>
                      <li><i class="fas fa-arrow-circle-right"></i> Milia</li>
                      <li><i class="fas fa-arrow-circle-right"></i> Keratosis of Face</li>
                      <li><i class="fas fa-arrow-circle-right"></i> Neck Corns</li>
                      <li><i class="fas fa-arrow-circle-right"></i> Skin Papilloma on Face</li>                      
                    </ul>
                    <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                    </div>
                </div>
                
            </div>
        </div>
    </>
  );
}
