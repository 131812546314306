import React from 'react';
import { Link } from 'react-router-dom';

export default function SeniorCitizens() {
  return (
    <>
        <div className='services-item container'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle mob-order-2 p-0'>
            <div className='services-item-content'>
              <h2>Clinics <span>Visit</span></h2> 
              <p>We provide consultations, diagnostics, vaccinations, lab investigations, and other paramedical services, even on Sundays and public holidays.</p>         
              <Link to="/ClinicVisit-BhartiyaCity" className="btn theam-btn-m">Clinic Visit at Bhartiya City</Link>
              <Link to="/ClinicVisit-Narayanapura" className="btn theam-btn-m">Clinic Visit at Narayanapura</Link>
              
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mob-order-1 p-0'>
          <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/hospital-img.png`} alt="Home Visits" />
          </div>
        </div>
      </div> 
    </>
  );
}
