import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';


const settings = {
    dots: true, // Show pagination dots
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default number of items for desktop
    slidesToScroll: 1,
    arrows: true, // Enable navigation arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // 1 item for mobile screens
          slidesToScroll: 1,
          dots: true,
          arrows: true, // Enable arrows on this breakpoint
        },
      },
    ],
  };

export default function LatestArticles() {   

  return (
    <>

<div className="container">
<div className='articles-section'>
          <h2>Read Our <span>Latest Articles</span></h2>
          <p className='limit-width'>Transform your health with our General & specialized services. Our expert team ensures comprehensive care for a confident lifestyle.</p>
          

      
          <Slider {...settings}>
          <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="https://famphy.com/blog/how-to-treat-a-cold-during-winter-in-india/" target="_blank" rel="noopener noreferrer">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/winter.jpg`} alt="slide 4" />
                      
                    <h5>How to Treat a Cold During Winter in India</h5>
                    <p>Winter in India can be quite chilly, especially in the northern regions. With the drop…</p>
                    <p>  December 25, 2024 by FamPhy Admin</p></Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="https://famphy.com/blog/safeguarding-yourself-from-pollution/" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/girl-wearing-face-mask-extreme-pollution.jpg`} alt="slide 1" />
                    <h5>Safeguarding Yourself from Pollution.</h5>
                    <p>Delhi’s air quality often reaches alarming levels, especially during the winter months. Here are some…</p>
                    <p> October 24, 2024 by FamPhy Admin</p>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                <Link to="https://famphy.com/blog/how-to-maintain-immunity-during-winters-in-ndia/" target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/close-up-portrait-woman-white-sweater-with-tea-1.jpg`} alt="slide 2" />
                        <h5>How to Maintain Immunity During Winters in India</h5>
                        <p>Winter in India brings a delightful change in weather, but it also comes with its…</p>
                        <p>October 24, 2024 2 by FamPhy Admin</p>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="https://famphy.com/blog/food-poisoning-a-general-body-illness/" target="_blank" rel="noopener noreferrer">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/medium-shot-women-talking-about-fruits.jpg`} alt="slide 2" />
                    <h5>FOOD POISONING: A general body illness</h5>
                    <p>Food poisoning is an unpleasant experience that can affect anyone. It’s a condition caused by…</p>
                    <p>October 24, 2024 by FamPhy Admin</p>
                    </Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="https://famphy.com/blog/the-lifesaving-importance-of-regular/" target="_blank" rel="noopener noreferrer">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/woman-measuring-pressure-pulse-patient.jpg`} alt="slide 4" />
                      
                    <h5>The Lifesaving Importance of Regular…</h5>
                    <p>In today’s fast-paced world, prioritizing your health has never been more crucial. At FamPhy Clinic,…</p>
                    <p> October 24, 2024 by FamPhy Admin</p></Link>
                </div>
            </div>
          
           {/*
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-04.png`} alt="slide 4" />
                      <p><strong>Clinic, Medical, Procedures, Treatment</strong></p>
                    <h5>Dr. Soumik Kalita pushing for a person...</h5>
                    <p>In a time when the prevailing trend across the nation leans towards specialized and tertiary healthcare institutions, renowned...</p>
                    <p> October 6, 2023 by FamPhy Admin</p></Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-05.png`} alt="slide 4" />
                      <p><strong>Clinic, Eye Care, Medical</strong></p>
                    <h5>COMPUTER VISION SYNDROME: General...</h5>
                    <p>With technological advancements in the 21st century, we are surrounded by digital screens all the time, be it computers,...</p>
                    <p> October 6, 2023 Я by FamPhy Admin</p></Link>
                </div>
            </div>
            <div className='articles-item'>
                <div className='shadow-box'>
                    <Link to="#">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/articles-img-06.png`} alt="slide 4" />
                      <p><strong>Body, Clinic, Medical</strong></p>
                    <h5>FOOD POISONING: A general body illness</h5>
                    <p>You wake up in the morning and rush to your bathroom vomiting and you feel diarrhea coming in too. Rewind back to...</p>
                    <p> October 6, 2023 by FamPhy Admin</p></Link>
                </div>
            </div>*/}
            
          </Slider>
      </div>
      </div>
      
    </>
  );
}
