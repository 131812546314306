import React from 'react';

export default function WelcomeFamphyClinics() {
  return (
    <>
        <div className="container mb-5">
            <div className='specialized-section'>
                <h2>Welcome to <span>FamPhy Clinics</span></h2>
                <p className='limit-width'>We provide consultations, diagnostics, vaccinations, lab investigations, and other paramedical services, even on Sundays and public holidays.</p>
            </div>
        
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0 working-time-table-box'>
                    <div className='services-item-content'>
                    <h3>Clinic  <span>Timing</span></h3>          
                    <table className='working-time-table'>
                        <tbody>
                            <tr>
                                <td>Monday</td>
                                <td>08:00 AM - 09:00 PM</td>
                            </tr>
                            <tr>
                                <td>Tuesday</td>
                                <td>08:00 AM - 09:00 PM</td>
                            </tr>
                            <tr>
                                <td>Wednesday</td>
                                <td>08:00 AM - 09:00 PM</td>
                            </tr>
                            <tr>
                                <td>Thursday</td>
                                <td>08:00 AM - 09:00 PM</td>
                            </tr>
                            <tr>
                                <td>Friday</td>
                                <td>08:00 AM - 09:00 PM</td>
                            </tr>
                            <tr>
                                <td>Saturday</td>
                                <td>08:00 AM - 09:00 PM</td>
                            </tr>
                            <tr>
                                <td>Sunday</td>
                                <td>08:00 AM - 09:00 PM</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/hospital-img.png`} alt="Home Visits" />
                </div>
            </div>
        </div>
    </>
  );
}
