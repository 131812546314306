import React from 'react';
import whatsapp from '../../assets/whatsapp.svg';

export default function Phone() {
  const whatsappNumber = "919868336833"; // WhatsApp number in international format without '+'

  return (
    <div className="phone-no-box">
      <p>
        <img src={whatsapp} alt="Famphy site" className="phone-icon" /> 
        <a 
          href={`https://wa.me/${whatsappNumber}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="whatsapp-link"
        >
          +91 98 6833 6833
        </a>
      </p>
    </div>
  );
}
