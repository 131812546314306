import React from 'react';

export default function FamphyLeadershipTeam() {
  return (
    <>
        <div className="container mb-5">
            <div className='specialized-section'>
                <h2>FamPhy <span>Leadership Team</span></h2>
                <p className='limit-width'>At FamPhy, our commitment to providing exceptional medical care is driven by the expertise and dedication of our distinguished leadership team of doctors. These visionary medical professionals are at the forefront of our mission to deliver high-quality healthcare services to our patients. Allow us to introduce you to the individuals who lead with passion, experience, and unwavering commitment to your health and well-being.</p>
            </div>
            <div className='row about-content'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="card">                    
                    <img className="card-img-top" src={`${process.env.PUBLIC_URL}/img/famphy-team-dr-soumik.png`} alt="Famphy Leadership Team" />
                        <div className="card-body">
                            
                            <p>Dr. Soumik Kalita</p>
                            <p className="card-text">Soumik Kalita is the Founder of FamPhy and Co-Founder of FamPhy Clinic. FamPhy specializes in quality medical care at home. He also trains doctors in India on Family Medicine as a faculty of CMC Vellore Family Medicine programme. He has an experience of more than 20 years in several aspects of healthcare ranging from being a medical officer in a primary health centre in rural India, to a research scientist with the South Asian Cochrane Network & Centre. Dr Soumik Kalita was the founder of Prima Clinics which was one of the 1st chain of family practice clinics in India.</p>
                            
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className="card">
                    <img className="card-img-top" src={`${process.env.PUBLIC_URL}/img/famphy-team-dr-anudeep.png`} alt="Famphy Leadership Team" />
                       
                        <div className="card-body">
                           
                            <p>Dr. Anudeep Kaur</p>
                            <p className="card-text">Dr Anudeep Kaur is health care professional and an alumnus of IIHMR and has 15+ years of experience in Health care Industry and has been a distinction holder in healthcare Operations . She was selected by John Hopkins for exchange programme with IIHMR. She has worked in various leadership roles in multiple corporate hospitals prior to FamPhy. She expertise in Green field projects and scaling them to next level.</p>
                            
                        </div>
                    </div>
                </div>
                </div> 
                <div className='specialized-section'>
                <h2>FamPhy <span>Advisory Board</span></h2>
                <p className='limit-width'>At FamPhy, we are committed to delivering exceptional healthcare services guided by the expertise of our esteemed Advisory Board. The Advisory Board consists of leading professionals from various fields of medicine, healthcare management, and patient advocacy. Their strategic insights and clinical expertise play a vital role in shaping the hospital’s policies, services, and patient care standards.</p>
            </div>
                <div className='row about-content'>  
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <div className="card">
                    <img className="card-img-top" src={`${process.env.PUBLIC_URL}/img/famphy-team-03.png`} alt="Famphy Leadership Team" />
                       
                        <div className="card-body">
                            <h5 className="card-title">Advisory Board</h5>
                            <p>Dr. Nayanjeet Chaudhury</p>
                            <p className="card-text">Dr. Nayanjeet Chaudhury is a fellow of the Global Health Leadership Program of Public Health Institute, Oakland CA and serving as Country Technical Adviser (India) for Medtronic Philanthropy since June 2015.</p>
                            <details>
                            <summary>read more</summary>
                           <p> A recipient of Rotary International scholarship (2005-06), Nayanjeet is a senior public health specialist with two decades of experience in primary care development, public health education, clinical and epidemiological research and program evaluation. Also, he is an avid promoter of lifestyle medicine for</p>
                           </details>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <div className="card">
                    <img className="card-img-top" src={`${process.env.PUBLIC_URL}/img/famphy-team-04.png`} alt="Famphy Leadership Team" />
                        <div className="card-body">
                            <h5 className="card-title">Advisory Board</h5>
                            <p>Dr. Jonathan Holdsworth</p>
                            <p className="card-text">Dr Jonathan has worked full time as family medicine doctor in Bristol in the south of England for the last 19 years and still enjoys going to work every day. He is the managing partner in a practice of 14,000 registered patients

                            <details>
                            <summary>read more</summary> with 9 doctors and 6 nurses.read moreHe is very interested in teaching and training of doctors who are attached to his practice. He also conducts annual appraisals on doctors in his city and is a Fellow of the Royal College of General Practitioners.
                            He has participated in teaching programmes for doctors in Eastern Europe and India focusing in whole person medicine. Dr Jonathan will mentor the doctors of FamPhy and will help in taking holistic primary care to a higher level.
                            </details>
                            </p>                            
                        </div>
                    </div>
                </div> 
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <div className="card">
                    <img className="card-img-top" src={`${process.env.PUBLIC_URL}/img/famphy-team-05.png`} alt="FamPhy Leadership Team" />
                        <div className="card-body">
                            <h5 className="card-title">Advisory Board</h5>
                            <p>Dr. Arindam Bharadwaz</p>
                            <p className="card-text">Dr. Arindam Bharadwaz is a Senior Consultant Interventional Radiologist, at Aarhus University Hospital C, and Palle Juul Jensens boulevard, Skejby, Aarhus, Denmark. He is the Chief Coordinator of the Nordic / Scandinavian societies 
                            <details>
                            <summary>read more</summary> of Interventional Radiology (Denmark, Sweden, Norway and Finland).read moreDr Bharadwaz has a keen interest in empowering family physicians with basic point of care diagnostic tools for better patient management at home or in a clinic set up and he is advising FamPhy on the same.
                            </details>
                            </p>                            
                        </div>
                    </div>
                </div>  
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <div className="card">
                    <img className="card-img-top" src={`${process.env.PUBLIC_URL}/img/famphy-team-06.png`} alt="Famphy Leadership Team" />
                        <div className="card-body">
                            <h5 className="card-title">Advisory Board</h5>
                            <p>Sandeep Aggarwal</p>
                            <p className="card-text">Sandeep Aggarwal is the founder of two marketplaces in India - Droom, India's first automobile marketplace and ShopClues, India's first and largest managed marketplace.
read moreSandeep Aggarwal is an angel investor, 
<br/>
                            <details>
                            <summary>read more</summary>
                            serial entrepreneur, philanthropist and Internet visionary and has successfully managed to build ShopClues as one of the top 5 Unicorns in Consumer Internet in India. Having worked in Silicon Valley and Seattle for about 12 years prior to founding ShopClues.com, he holds rich experience in the Internet and E-commerce industry. He is an MBA from Washington University in St. Louise and holds a US patent.
                            </details>
                            </p>                            
                        </div>
                    </div>
                </div>           
                
            </div>
        </div>
    </>
  );
}
