import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

export default function Specialized() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        },
      },
    ],
  };

  // Handle hash-based scrolling
  const ScrollToHashElement = () => {
    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);
  };

  ScrollToHashElement(); // Call the scrolling logic inside the component

  return (
    <div className="container">
      <div className='specialized-section'>
        <h2>Specialized & <span>Preventive Care</span></h2>
        <p className='limit-width'>Transform your health with our General & specialized services. Our expert team ensures comprehensive care for a confident lifestyle.</p>

        <Slider {...settings}>
          <div className='articles-item'>
            <div className='shadow-box'>
              <Link to="/our-services#homeVisit">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-01.jpg`} alt="slide 1" />
                <h5>Home Visits</h5>
              </Link>
            </div>
          </div>
          <div className='articles-item'>
            <div className='shadow-box'>
              <Link to="/our-services#videoConsultations">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-02.jpg`} alt="slide 2" />
                <h5>Video Consultations</h5>
              </Link>
            </div>
          </div>
          <div className='articles-item'>
            <div className='shadow-box'>
              <Link to="/our-services#familyPhysicians">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-03.jpg`} alt="slide 3" />
                <h5>Family Physicians</h5>
              </Link>
            </div>
          </div>
          <div className='articles-item'>
            <div className='shadow-box'>
              <Link to="/our-services#labTests">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-04.jpg`} alt="slide 4" />
                <h5>Lab Tests</h5>
              </Link>
            </div>
          </div>
          <div className='articles-item'>
            <div className='shadow-box'>
              <Link to="/our-services#vaccinations">
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-05.jpg`} alt="slide 5" />
                <h5>Vaccinations</h5>
              </Link>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
