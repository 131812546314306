import React from 'react';
import { Link } from 'react-router-dom';
export default function VideoConsultations() {
  return (
    <>
      <div className='services-item container' id="videoConsultations">
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-02.png`} alt="Home Visits" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                <div className='services-item-content'>
                <h2>Video   <span>Consultations</span></h2>          
                <p>The world of healthcare is rapidly transforming, and one of the most significant innovations is Doctor Video Consultations. With the rise of telemedicine, patients now have access to qualified healthcare professionals from the comfort of their own homes. Whether you’re seeking a quick diagnosis, follow-up care, or advice on managing chronic conditions, video consultations provide a convenient and efficient way to meet your health needs.</p>
                <Link to="/contact" className="btn theam-btn-m">Contact Us</Link>
                </div>
            </div>          
        </div>
      </div>
    </>
  );
}
