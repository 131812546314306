import React from 'react';
import { Link } from 'react-router-dom';

export default function ContactAddress() {
  return (
    <>
      <h2>Contact <span>Us</span></h2>
      <p><i className="fas fa-home mr-3"></i> Lower Ground Floor, Mall of Bengaluru, Bhartiya City, Kannuru, Bengaluru, 560064</p>
      <p><i className="fas fa-envelope mr-3"></i> hello@famphy.com</p>
      <p><i className="fas fa-phone mr-3"></i> +91 96 0615 8631</p>
      
      <div className="social-media mb-5">
        <Link to="/"><i className="fab fa-facebook-f"></i></Link>
        <Link to="/"><i className="fab fa-twitter"></i></Link>
        <Link to="/"><i className="fab fa-google"></i></Link>
        <Link to="/"><i className="fab fa-instagram"></i></Link>
      </div>

      <iframe
        title="Famphy Location on Google Maps" // Add a title here
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15544.976853509268!2d77.62478117372963!3d13.083703145941087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae193e13bef207%3A0x16c259b2680bf32f!2sFamily%20Clinics%20-%20Unit%20of%20Famphy!5e0!3m2!1sen!2sin!4v1735108293404!5m2!1sen!2sin"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}
