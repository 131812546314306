import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { post } from '../utils/httpClient';
import { Link } from 'react-router-dom';

const initialData = {
  phoneNumber: '',
  alternatePhoneNumber: '',
  nameOfPerson: '',
  firstNameOfPatient: '',
  lastNameOfPatient: '',
  ageOfPatient: '',
  genderOfPatient: '',
  dateOfBirth: '',
  chiefComplaint: '',
  address: '',
  landmark: '',
  city: '',
  state: '',
  pinCode: '',
  email: '',
  mobile: '',
};

const AppointmentForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Simple validation
    const newErrors = {};
    const requiredFields = [
      'phoneNumber', 'nameOfPerson', 'firstNameOfPatient', 'lastNameOfPatient',
      'ageOfPatient', 'dateOfBirth', 'chiefComplaint', 'address', 'landmark',
      'city', 'state', 'pinCode', 'email', 'mobile'
    ];

    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} is required`;
      }
    });

    // Email validation
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
    }

    // Mobile validation
    if (formData.mobile && !/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = 'Mobile number must be 10 digits';
    }

    // Update errors state
    setErrors(newErrors);

    // If no errors, handle form submission (e.g., send data to a server)
    if (Object.keys(newErrors).length === 0) {
      console.log(formData);
      // Reset form fields
      // setFormData(initialData);
      try {
        setIsSubmitting(true);
        const response = await post('appointment.php', formData);
        console.log(response);
        Swal.fire({
          title: 'Success!',
          html: `<div>
                  <h3>Thank you for contacting us!</h3>
                  <p>We will get back to you shortly.</p>
                </div>`,
          icon: 'success',
          confirmButtonText: 'OK'
        });
        setFormData(initialData);
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: 'Error!',
          text: 'Some error occured. Please try again',
          icon: 'error',
          confirmButtonText: 'Retry'
        });
      } finally {
        setIsSubmitting(true);
      }
    }
  };

  return (
    <>
    <div className='Inner-banner'>
        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/inner-banner-06.jpg`} alt="Home Visits" />
      </div>
      <div className="bookapointment">
      <div className="container mt-5 mb-5">
        <form onSubmit={handleSubmit}>
          <div class="form-group">
          <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12 bookapointment-form">
            <h2>Book an <span>Appointment</span></h2>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Phone Number *</label>
                <input
                  className='form-control'
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
                {errors.phoneNumber && <span>{errors.phoneNumber}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Alternate Phone Number</label>
                <input
                  className='form-control'
                  type="text"
                  name="alternatePhoneNumber"
                  value={formData.alternatePhoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Name of Person Booking Appointment *</label>
                <input
                  className='form-control'
                  type="text"
                  name="nameOfPerson"
                  value={formData.nameOfPerson}
                  onChange={handleChange}
                />
                {errors.nameOfPerson && <span>{errors.nameOfPerson}</span>}
              </div>
            
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>First Name of Patient *</label>
                <input
                  className='form-control'
                  type="text"
                  name="firstNameOfPatient"
                  value={formData.firstNameOfPatient}
                  onChange={handleChange}
                />
                {errors.firstNameOfPatient && <span>{errors.firstNameOfPatient}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Last Name of Patient *</label>
                <input
                  className='form-control'
                  type="text"
                  name="lastNameOfPatient"
                  value={formData.lastNameOfPatient}
                  onChange={handleChange}
                />
                {errors.lastNameOfPatient && <span>{errors.lastNameOfPatient}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Age of Patient *</label>
                <input
                  className='form-control'
                  type="number"
                  name="ageOfPatient"
                  value={formData.ageOfPatient}
                  onChange={handleChange}
                />
                {errors.ageOfPatient && <span>{errors.ageOfPatient}</span>}
              </div>
            
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Gender of Patient</label>
                <select
                  className='form-control'
                  name="genderOfPatient"
                  value={formData.genderOfPatient}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Date of Birth *</label>
                <input
                  className='form-control'
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                />
                {errors.dateOfBirth && <span>{errors.dateOfBirth}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Chief Complaint *</label>
                <textarea
                  className='form-control'
                  rows="1"
                  name="chiefComplaint"
                  value={formData.chiefComplaint}
                  onChange={handleChange}
                />
                {errors.chiefComplaint && <span>{errors.chiefComplaint}</span>}
              </div>
            
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Address for visit and Landmark *</label>
                <input
                  className='form-control'
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
                {errors.address && <span>{errors.address}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Landmark *</label>
                <input
                  className='form-control'
                  type="text"
                  name="landmark"
                  value={formData.landmark}
                  onChange={handleChange}
                />
                {errors.landmark && <span>{errors.landmark}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>City *</label>
                <input
                  className='form-control'
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
                {errors.city && <span>{errors.city}</span>}
              </div>
            
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>State *</label>
                <input
                  className='form-control'
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
                {errors.state && <span>{errors.state}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Pin Code *</label>
                <input
                  className='form-control'
                  type="text"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleChange}
                />
                {errors.pinCode && <span>{errors.pinCode}</span>}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>E-mail of Person Booking Appointment *</label>
                <input
                  className='form-control'
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <span>{errors.email}</span>}
              </div>
            
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>Mobile of Person Booking Appointment *</label>
                <input
                  className='form-control'
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
                {errors.mobile && <span>{errors.mobile}</span>}
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <button type="submit" className='btn theam-btn-m mw-60'>Submit</button>
              </div>
            </div>
            </div>
          <div className="col-lg-5 col-md-5 col-sm-12 img-section"> 
            <div className="row">         
              <div className="col-lg-12"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-img-02.png`} alt="Home Visits" /></div>
             {/*  <div className="col-lg-12 mt-5"><Link to="/online-payment" className="btn btn-danger btn-lg btn-block">Pay Online</Link></div>*/}
            </div>
          </div>
          </div>
           
              
            
          </div>
        </form>
      </div>
      </div>
    </>
  );
};

export default AppointmentForm;
