import React from 'react';
import { Link } from 'react-router-dom';
export default function ClinicDayCareNarayanapura() {
  return (
    <>
      <div className='services-item container'>
            <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/clinic-visit-05.png`} alt="Home Visits" />
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                    <div className='services-item-content'>
                    <h2><span>Day Care Procedures</span></h2>  
                    <ul className='list-unstyled text-start margin-left-30'>
                    <li><i class="fas fa-arrow-circle-right"></i> Nebulization</li>
                    <li><i class="fas fa-arrow-circle-right"></i> I V Fluid Administrate</li>
                    <li><i class="fas fa-arrow-circle-right"></i> Dressing</li>                    
                    </ul>    
                    <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                    </div>
                </div>
                
            </div>
        </div>
    </>
  );
}
