import React from 'react';
import { Link } from 'react-router-dom';
export default function HomeVisits() {
  return (
    <>
      <div className='services-item container' id="homeVisit">
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle mob-order-2 p-0'>
            <div className='services-item-content'>
              <h2>Home  <span>Visits</span></h2>          
              <p>In today's fast-paced world, healthcare has evolved to become more patient-centric, offering solutions that provide convenience without compromising quality. Our Doctor Visits at Home service allows patients to receive expert medical care in the comfort of their own space. Whether for routine checkups, post-surgery care, or chronic disease management, at-home doctor visits offer a flexible, personalized healthcare experience.

</p>
              <Link to="/contact" className="btn theam-btn-m">Contact Us</Link>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mob-order-1 p-0'>
          <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-01.png`} alt="Home Visits" />
          </div>
        </div>
      </div>
    </>
  );
}
