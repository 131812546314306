import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navbarRef = useRef(null);

  // Function to handle clicks outside the navbar or dropdown
  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setExpanded(false); // Collapse the navbar
      setDropdownOpen(false); // Close dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav ref={navbarRef} className="main-nav navbar navbar-expand-lg">
      <button
        className="navbar-toggler"
        type="button"
        aria-expanded={expanded ? "true" : "false"}
        aria-label="Toggle navigation"
        onClick={() => setExpanded(!expanded)}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`collapse navbar-collapse ${expanded ? "show" : ""}`} id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/our-services">
              Our Services
            </Link>
          </li>
          <li
            className={`nav-item dropdown ${dropdownOpen ? "show" : ""}`}
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              role="button"
              aria-expanded={dropdownOpen ? "true" : "false"}
            >
              Clinic Visit
            </Link>
            <div className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
              <Link className="dropdown-item" to="/ClinicVisit-BhartiyaCity">
                Clinic Visit at Bhartiya City
              </Link>
              <Link className="dropdown-item" to="/ClinicVisit-Narayanapura">
              Clinic Visit at Narayanapura
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about-us">
              About Us
            </Link>
          </li>
         {/* <li className="nav-item">
            <Link className="nav-link" to="/faq">
              FAQ
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/gallery">
              Gallery
            </Link>
          </li>*/}
          <li className="nav-item">
          <Link className="nav-link" to="https://famphy.com/blog/" target="_blank" rel="noopener noreferrer">
  Blog
</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contacts">
              Contact Us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/book-appointment">
              Book Appointment
            </Link>
          </li>
          
        </ul>
      </div>
    </nav>
  );
}
