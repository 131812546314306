import React, { useEffect } from 'react';
import baguetteBox from 'baguettebox.js';
import 'baguettebox.js/dist/baguetteBox.min.css'; // Ensure you import the CSS for baguetteBox

const images = [
  {
    src: "/img/famphy-team-01.png",
    alt: "Famphy Gallery"
  },
  {
    src: "/img/famphy-team-02.png",
    alt: "Famphy Gallery"
  },
  {
    src: "/img/famphy-team-dr-anudeep.png",
    alt: "Famphy Gallery"
  },
  {
    src: "/img/hospital-img.png",
    alt: "Famphy Gallery"
  },
  {
    src: "/img/famphy-team-dr-soumik.png",
    alt: "Famphy Gallery"
  },
  {
    src: "/img/teams-12.png",
    alt: "Famphy Gallery"
  }
];

const FamphyGallery = () => {
  useEffect(() => {
    baguetteBox.run('.tz-gallery'); // Initialize baguetteBox.js for lightbox effect
  }, []);

  return (
    <>
      <div className="inner-banner">
        <img
          className="img-fluid w-100"
          src={`${process.env.PUBLIC_URL}/img/famphy-inner-banner-gallery.jpg`}
          alt="Home Visits"
          style={{ maxHeight: '400px', objectFit: 'cover' }}
        />
      </div>

      <div className="container gallery-container mt-4">
        <h2 className="text-center mb-4">
          Famphy <span>Image Gallery</span>
        </h2>
        <div className="tz-gallery">
          <div className="row">
            {images.map((image, index) => (
              <div
                key={index}
                className="col-6 col-md-4 mb-4"
              >
                <a className="lightbox" href={image.src}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="img-fluid rounded shadow-sm"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FamphyGallery;
