import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function OurTeam() {

  const carouselRef = useRef(null);

  useEffect(() => {
    const handleSlide = (e) => {
      const target = e.relatedTarget;
      const idx = Array.from(target.parentNode.children).indexOf(target);
      const itemsPerSlide = 5;
      const totalItems = document.querySelectorAll('.carousel-item').length;

      if (idx >= totalItems - (itemsPerSlide - 1)) {
        const it = itemsPerSlide - (totalItems - idx);
        for (let i = 0; i < it; i++) {
          if (e.direction === 'left') {
            const item = document.querySelectorAll('.carousel-item')[i];
            document.querySelector('.carousel-inner').appendChild(item);
          } else {
            const item = document.querySelectorAll('.carousel-item')[0];
            document.querySelector('.carousel-inner').appendChild(item);
          }
        }
      }
    };

    const carouselElement = carouselRef.current;
    carouselElement.addEventListener('slide.bs.carousel', handleSlide);

    return () => {
      carouselElement.removeEventListener('slide.bs.carousel', handleSlide);
    };
  }, []);


  return (
    <>
      <div className="container">
        <div className='ourteam-section'>
          <h2>Our Team of <span>Doctors</span></h2>
          <p className='limit-width'>Specialized and general physicians who have many years of work experience in there respective fields</p>
          

      
          <div
            id="carouselTeam"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="9000"
            ref={carouselRef}
          >
          <div className="carousel-inner">
           
            <div className="carousel-item active">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-08.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr Sudarshan V</h5>
                      <p>MBBS, MS, FMAS, EFIAGES General and Laparoscopic surgeon. 
                       Is a compassionate and dedicated surgeon, with a keen eye for detail and precision. <details>
                      <summary>read more</summary>With over 5 years of experience in the field, his areas of interest lie in treatment of varicose veins, thyroid gland diseases, hernia repair surgeries and minimal access for gastrointestinal tract illnesses.</details></p>
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>MBBS,MS,FMAS,EFIAGE</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/* <Link to="#" className="btn theam-btn-m">View Profile</Link>*/}
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-02.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Aperna Tej</h5>
                      <p>Senior Physiotherapist with 15+ years of experience in joint care, musculoskeletal health, and geriatrics. She is also trained in treating stroke patients.</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>15+ Years of Exp</span> 
                        <span className='mst-in'>Physiotherapist</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/*<Link to="#" className="btn theam-btn-m">View Profile</Link>*/}
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-03.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr.Kritika Patel</h5>
                      <p>MBBS MD (Paediatrics), Fellowship in Neonatology Consultant Paediatrician and Neonatologist in several hosptials</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>MBBS MD (Paediatrics)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/* <Link to="#" className="btn theam-btn-m">View Profile</Link>*/}
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-12.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr Sarita R.</h5>
                      <p>Obstetrician and gynecologist with 15+ years of experience.
                          She has been trained in advanced Laproscopic Procedures as well and is also member of minimal access surgery. She has worked in varoius corporate hospitals like ASTER.
                          She is available for Consultations and Gyn Procedures in famphy clinics</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>15+ Years of Exp</span> 
                        <span className='mst-in'>Obstetrician and Gynecologist</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>                         
                      </div>
                    </div>  
                  </div>
                </div>
                {/*<div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-05.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr.Priyanka Apte</h5>
                      <p>MBBS PGDFM (CMC Vellore) Family Physician</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>MBBS PGDFM (CMC Vellore)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                          <Link to="#" className="btn theam-btn-m">View Profile</Link>
                      </div>
                    </div>  
                  </div>
                </div>*/}
                
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-04.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Siddharth Sakelle</h5>
                      <p>20+ years of Experience Physiotherapist .He is a 
Head Physiotherapist in Prakash Padukone Badminton Academy (Bangalore) He has done his

masters in Orthopedic,Sports and Manual therapy (Australia)and is a 
Certified Ergonomics from USA.
<details>
<summary>read more</summary>
He is also an expert in Manual and manipulation therapy
He was the Personal physio of Saina Nehwal (Badminton Great) 
Ex Personal Physio of Lakshya Sen (CWG Gold medalist) and has 
Worked with great MC marykom(Olympic medalist Boxer), Deepika Kumari (Ex world no 1 archer).</details></p>
                      <div className='ext-content'>
                        <span className='exp-yers'>20+ Years of Exp</span> 
                        <span className='mst-in'>BPT, Masters in Orthopedic</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/*<Link to="#" className="btn theam-btn-m">View Profile</Link>*/}
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-06.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>DR ADITYA PREM KUMAR</h5>
                      <p>Is an Orthopedic Surgeon. from Vydehi Institute
of Medical Sciences. He completed his fellowship in arthroscopy and. Sports Medicine from HOSMAT
Apart from his passion of Sports Medicine he was trained in
was Knee Replacement in Ayon FRANCE. He also completed his Post-grad diploma in Exercise Medicine,</p>
                      {/*<p>Dr. Aditya Premkumar is an orthopedic surgeon from Bangalore, having received training from multiple institutes in India and abroad as well.
                        <details>
                        <summary>read more</summary> He completed his MBBS from JSS Medical College, Mysore, following which was trained in orthopedic surgery in Vydehi Institute of Medical Sciences and BGS Global Gleneagles Hospital. In order to pursue his passion of sports medicine and trauma, he completed a fellowship in Arthroscopy and Sports Medicine from HOSMAT Hospital. In order to increase his focus on knee surgery as a whole, he received further training in one of Europe's premier joint replacement centers in Lyon, France.
                        He has also completed a Post-grad Diploma degree in Sports and Exercise Medicine from the UK to enhance and upgrade his knowledge on injury prevention, rehabilitation and more.
                        His areas of interest are Hip and Knee joint replacements, Sports Medicine and Sports Traumatology.</details></p>*/}
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>Orthopedic Surgeon</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/*<Link to="#" className="btn theam-btn-m">View Profile</Link>*/}
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>


            <div className="carousel-item">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famPhy-team-16.jpg`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr Nidhi Gupta</h5>
                      <p>India and US trained in Internal medicine and nephrology . 
A personable,efficient and versatile physician who believes in treating the patient as well as the disease. 
Specializes in hypertension , acute and chronic kidney disease , diabetic kidney disease, kidney stone disease,  glomerulonephritis, kidney transplant management .</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>10+ Years of Exp</span> 
                        <span className='mst-in'>Medicine & Nephrology</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/* <Link to="#" className="btn theam-btn-m">View Profile</Link> */}
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famPhy-team-15.jpg`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr Sharath G</h5>
                      <p>Fellowship in rhinology and anterior skull base surgery</p>
                      <p>Dr. Sharath.G practices as an E.N.T specialist with a 12years experience in this field. He specializes in sinus, nose and allergic disorders.
                          He is fellowship trained in rhinology and skull base surgery Has his  expertise in </p>
                    <ul className='list-group'>
                      <li>Endoscopoc Sinus surgery and septoplasty </li>
                      <li>coblation and laser assisted surgeries for adenoids and tonsils</li>
                      <li>C.S.F leak repairs, </li>
                      <li>snoring surgeries </li>
                      <li>Tympanoplasty</li>
                    </ul>
                      
                      <div className='ext-content'>
                        <span className='exp-yers'>17+ Years of Exp</span> 
                        <span className='mst-in'>M.S ENT</span>
                      </div>
                      <div className="team-btn-box">
                        {/*<Link to="/contacts" className="btn theam-btn-m">Consult</Link>   */}                      
                      </div>
                    </div>  
                  </div>
                </div>
                {/*<div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-12.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr Sarita R.</h5>
                      <p>Obstetrician and gynecologist with 15+ years of experience.
                          She has been trained in advanced Laproscopic procedure s well and is lso member of minimal access surgry. she has worked in varoius corporate hospitals like aster.
                          She is available in famphy clinics</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>15+ Years of Exp</span> 
                        <span className='mst-in'>Obstetrician and Gynecologist</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>                         
                      </div>
                    </div>  
                  </div>
                </div>*/}
                
              </div>
            </div>
            




            <div className="carousel-item">
              <div className="row">              
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-11.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr Bhima Sheshagiri </h5>
                      <p>MBBS ,MD (Dermatology)
30 + years of experience in India and abroad 
He expertise in Dermatological cases ,Venereology and Aesthetics ( Removal of moles ,skin tags, milia, Seborrheic keratosis  and small skin tumours
Hydrafacial for  Rejuvenation of the face
PRP and Mesotherapy</p>
                      <div className='ext-content'>
                        <span className='exp-yers'>30+ Years of Exp</span> 
                        <span className='mst-in'>MBBS ,MD (Dermatology)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/*<Link to="#" className="btn theam-btn-m">View Profile</Link>*/}
                      </div>
                    </div>  
                  </div>
                </div>
                <div className="col-md-6">
                  <div className='shadow-box row'>
                    <div className="col-lg-5 col-md-5 col-sm-5 teamimg">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/teams-13.png`} alt="slide 3" />
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-7 teamcontent">
                      <h5>Dr Sivakumar Buddha</h5>
                      <p> Dr. Siva is a seasoned physician with over 13 years of experience, holding an MBBS and a Fellowship in Emergency Medicine. He is certified ACLS and PALS. He has worked with leading healthcare institutions, including Vikram Hospital and Aditya Birla Memorial Hospital in Pune, .<details>
                      <summary>read more</summary>where he gained extensive expertise in managing critical emergencies and complex medical cases.

Known for his quick decision-making and patient-centered care, Dr. Siva excels in high-pressure environments, delivering timely interventions. He is also passionate about mentoring young medical professionals and fostering teamwork for optimal patient outcomes. With a strong commitment to medical excellence and compassionate care, Dr. Siva continues to make a positive impact in healthcare.</details></p>
                      <div className='ext-content'>
                        <span className='exp-yers'>13+ Years of Exp</span> 
                        <span className='mst-in'>Seasoned Physician (MBBS)</span>
                      </div>
                      <div className="team-btn-box">
                        <Link to="/contacts" className="btn theam-btn-m">Consult</Link>
                         {/*<Link to="#" className="btn theam-btn-m">View Profile</Link>*/}
                      </div>
                    </div>  
                  </div>
                </div>
                
              </div>
            </div>


          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselTeam" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselTeam" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      </div>
    </>
  );
}
