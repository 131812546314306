import React from 'react';
import { Link } from 'react-router-dom';

export default function ClinicLabTests() {
  return (
    <>
      <div className='services-item container'>
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 p-0'>
            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/famphy-services-04.png`} alt="Home Visits" />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 v-align-middle p-0'>
                <div className='services-item-content'>
                <h2>Lab <span>Investigations</span></h2>          
                <p>We provide lab reports from NABL Accredited Labs. Home sample collection is available at no extra cost within a 2 km radius.</p>
                <Link to="/contacts" className="btn theam-btn-m">Contact Us</Link>
                </div>
            </div>          
        </div>
      </div>
    </>
  );
}
